<template>
  <div>
    <div v-if="data.local === false" id="hero" :style="`background: url(${data.image}) no-repeat top center; background-size: cover`">
      <div class="content">
        <span class="header" v-if="data.header">{{ data.header }}</span>
        <br />
        <span class="tag" v-if="data.tag">{{ data.tag }}</span>
      </div>
    </div>
    <div v-else id="hero" :style="`background: url(${require('@/assets/images/' + data.image + '')}) no-repeat top center; background-size: cover;`">
      <div class="content">
        <span class="header" v-if="data.header">{{ data.header }}</span>
        <br />
        <span class="tag" v-if="data.tag">{{ data.tag }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"]
};
</script>

<style lang="scss" scoped>
#hero {
  height: 300px;
  margin-bottom: 40px;

  @media only screen and (min-width: 768px) {
    height: 500px;
    margin-bottom: 60px;
  }

  @media only screen and (min-width: 992px) {
    height: 650px;
  }
}

#hero .content {
  top: 170px;

  @media only screen and (min-width: 768px) {
    top: 260px;
  }

  @media only screen and (min-width: 992px) {
    top: 400px;
  }
}

.header {
  display: inline-block;
  font-family: protipo, sans-serif;
  background-color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 40px;
  padding: 10px;
  margin-bottom: 5px;

  @media only screen and (min-width: 768px) {
    font-size: 70px;
    padding: 22px 10px;
    margin-bottom: 8px;
  }
}

.tag {
  display: inline-block;
  font-family: protipo, sans-serif;
  background-color: white;
  text-transform: uppercase;
  font-weight: 700;
  padding: 6px 10px 5px 10px;
  font-size: 24px;

  @media only screen and (min-width: 768px) {
    padding: 12px 10px 11px 10px;
    font-size: 36px;
  }
}
</style>
